@import './variables/variables';

.search-input {
    display: flex;
    align-items: center;
    position: relative;

    &__input {
        flex-grow: 1;
        height: 48px;
        border: 1px solid $light_gray4;
        border-radius: 6px;
        padding-left: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 12px 24px;
        padding-right: 76px;

        &:focus {
            outline: none;
            border: 1px solid $light_blue;
        }
    }

    &__btn {
        position: absolute;
        right: 0px;
        background: none;
        border: none;
        cursor: pointer;
        padding: 12px 28px;
    }
}