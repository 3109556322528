@import './variables/variables';


.service-card {
    &__container {
        padding-top: 23px;
        padding-left: 38px;
        padding-right: 26px;
        min-width: 380px;
        max-width: 380px;
        min-height: 257px;
        max-height: 257px;
        background-color: white;
        border-radius: 6px;
        box-shadow: 0px 4px 4px #F1F7FF;
        cursor: pointer;
    }

    &__title {
        max-height: 24px;
        overflow: hidden;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: $dark_blue;
        margin-bottom: 20px;
    }

    &__description {
        height: 34px;
        overflow: hidden;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $dark_blue;
        margin-bottom: 20px;
    }

    &__flex-container {
        display: flex;
        max-height: 137px;
    }

    &__image {
        display: flex;
        max-height: 121px;
        min-height: 121px;
        max-width: 194px;
        margin-left: auto;
        flex-grow: 0;

        img {
            object-fit: contain;
        }
    }

    &__advantage {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2C5BDE;
    }

    &__additional-info-container {
        display: flex;
        flex-direction: column;
        position: relative;

        &-item-new {
            width: 55px;
        }

        &-additional-info {
            position: absolute;
            bottom: 0;
            min-width: 150px;
            font-weight: 500;
            font-size: 12px;
            padding: 4px 12px;
            background-color: $primary_blue;
            color: white;
            border-radius: 6px;
        }
    }
}