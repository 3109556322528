.tariff-details {

    &-additional-info {
        margin-bottom: 120px;
    }

    &-additional-item {
        padding-top: 20px;
        padding-bottom: 20px;
        border-bottom: 1px solid $light_gray4
    }

    &__additional-value {
        font-size: 14px;
        padding: 10px;
    }
}