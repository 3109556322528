@mixin font($fontFamily, $type, $weight, $style: "normal") {
  $fontPath: "../fonts/#{$fontFamily}/#{$fontFamily}-#{$type}.ttf";

  @font-face {
    font-family: "#{$fontFamily}";
    src: local("#{$fontFamily} #{$type}"),
    local("#{$fontFamily}-#{$type}"),
    url("#{$fontPath}") format("truetype");
    font-weight: $weight;
    font-style: $style;
  }
}

@font-face {
  font-family: "Montserrat";
  src:
    url("../fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}

@include font('Montserrat', 'Regular', 400);
@include font('Montserrat', 'Medium', 500);
@include font('Montserrat', 'Bold', 600);
@include font('Montserrat', 'Bold', 700);