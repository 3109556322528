@import './variables/variables';

.connect-form {

    &__container {
        border: 1px solid $light_gray2;
        border-radius: 6px;
    }

    height: fit-content;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: $dark_blue;


    &__item {
        padding-left: 24px;
        padding-right: 24px;
        border-bottom: 1px solid $light_gray2;
        width: 380px;
        padding-top: 32px;

        &--main-header-padding {
            padding-top: 23px;
        }
    }

    &__item-header {
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: $primary_blue;
        margin-bottom: 20px;
    }


    &__header-constructor {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        font-weight: 700;
        font-size: 22px;
        line-height: 24px;
        color: $dark_blue;
        padding-bottom: 23px;
    }

    &__price {
        font-weight: 600;
        font-size: 22px;
        line-height: 16px;
        color: $dark_blue;
    }

    &__header-days {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: $primary_blue;
        display: flex;
        width: 100%;

        &--end {
            justify-content: end;
        }
    }

    &__logo {
        margin-right: 12px;
    }

    &__option-container {
        display: flex;
        align-items: center;
        margin-bottom: 32px;
    }

    &__connect-btn {
        padding: 14px 113px;
        font-size: 16px;
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 36px;
        margin-top: 28px;
    }

    &__month-paid-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
    }

    &__slider-container {
        display: flex;
        padding-bottom: 32px;
        column-gap: 12px;
    }

    &__toggles-wrapper {
        width: 100%;
    }

    &__toggle-description {
        font-size: 12px;
        color: $dark_gray1,
    }

}