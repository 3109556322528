$background_dark: #12253D;

$soft_blue: #B9C3F4;
$light_blue: #679AF0;
$primary_blue: #2C5BDE;
$dark_blue: #002233;

$light_gray1: #E5E5E5;
$light_gray2: #F1F1F1;
$light_gray3: #F7F9FC;
$light_gray4: #EFF3FB;
$light_gray5: #E3E3E3;

$dark_gray1: #6C6C6C;

$soft_purple: #C6B8D2;