@import './variables/variables';

.header {
    &__nav {
        height: 60px;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }

    &__nav-item {
        height: 52px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &--active {
            border-bottom: 2px solid $primary_blue;
            ;
        }
    }

    &__nav-link {
        color: #20264E;
    }

    &__nav-wrapper {
        background-color: $light_gray3;
    }

    &__logo-container {
        height: 60px;
        display: flex;
        align-items: center;
    }
}