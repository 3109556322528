@import './variables/variables';
@import "fonts";
@import 'header';
@import 'footer';
@import 'loader';
@import "tariffs";
@import "slider";
@import "tariff-card";
@import "buttons";
@import "constructor";
@import "inputs";
@import "range";
@import "connect-form";
@import "accordion";
@import "checkbox";
@import "parameters-list";
@import "tariff-details";
@import "devices-page-content";
@import "device-card";
@import "device-details";
@import "page-with-cards-list";
@import "service-card";
@import "service-details";
@import "image-slider";
@import "package-details";
@import "download-pdf-btn";
@import 'pdf';
@import "price-loader";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat';
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

li {
  list-style-type: none;
  /* Убираем маркеры */
}


ul {
  margin-left: 0;
  padding-left: 0;
}

#root {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

.container {
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 30px;
  width: 100%;

  &--padding-top {
    padding-top: 44px;
  }
}

.page-content-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;

  &--tariffs-filter {
    margin-bottom: 16px;
  }

  &--constructor {
    margin-bottom: 56px;
  }

  &--devices {
    margin-bottom: 22px;
  }

}

.flex-wrapper {
  display: flex;
}

.space-between {
  justify-content: space-between;
}



.path-info {
  height: 17px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $dark_gray1;
  display: flex;

  &__item {
    margin-right: 12px;
    cursor: pointer;

    &--active {
      color: $primary_blue;
    }
  }

  &__right-arrow {
    margin-right: 22px;
  }
}

.title {
  &--sm {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: $dark_blue;
  }
}