@import './variables/variables';

.pdf {
    &__header {
        padding: 14px 18px;
        display: flex;
        justify-content: space-between;
        border: 1px solid $light_gray5;

        &-start-tariff-date {
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }
    }

    &__tariff-description {
        padding: 0 18px;

        &-title {
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $primary_blue;
        }

        &-text {
            font-weight: 400;
            font-size: 10px;
            line-height: 24px;
        }
    }

    &__table {
        border-collapse: collapse;
        border-style: hidden;
        width: 100%;

        tr {
            border: 1px solid $light_gray5;
            border-top: none;
        }

        td {
            border: 1px solid $light_gray5;
            border-top: none;
            border-left: none;
            padding: 10px 18px;

        }

        &__section {
            background: $light_gray4;
            text-align: center;
        }

        &__value {
            width: 117px;
            font-weight: 400;
            font-size: 14px;
        }

        &__description {
            width: 478px;
            font-weight: 500;
            font-size: 14px;
        }

        &__parameter-description {
            font-weight: 400;
            font-size: 14px;
        }

        &__parameter-value {
            text-align: center;
        }
    }
}