@import './variables/variables';


.package-details {
    &__path-wrapper {
        padding-top: 20px;
        padding-bottom: 60px;
    }

    &__main-title {
        font-weight: 700;
        font-size: 36px;
        line-height: 44px;
        color: $dark_blue;
        margin-bottom: 22px;
    }

    &__package-info-container {
        width: 780px;
    }

    &__package-items-container {
        display: flex;
        flex-wrap: wrap;
        gap: 22px
    }

    &__package-items-details-container {
        padding-top: 20px;
    }

    &__package-items-details-title {
        margin-bottom: 10px;
    }
}